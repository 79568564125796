import React from 'react'
import { Link } from 'react-router-dom';
import Translation from "../../../../Images/Translation.jpg";
import TranslationNew from "../../../../Images/TranslationNew.jpg";

function TranslationDoc(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM Translations</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/GOMLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GOM Translations</h5>
                <p>The Translations provides a method of translating results, methods and textual specifications from the value in SampleManager to a langauge
                requested by GOM.  This allows a single Component Group to be used for more than one language and provides a mechanism to translate specifications
                and methods.
                </p>
                <ul>
                    <li><b>Site</b> is the value from the Site table.</li>
                    <li><b>Language</b> ISO language code as sent from GOM.  Currently only en (English) and zh (Chinese) are supported.</li>
                    <li><b>Translation Type</b> may be Result, Method or Specifications .</li>
                    <li><b>SampleManager Text</b> must match the value from SampleManager.</li>
                    <li><b>CofQ Text</b> is the text that will be printed on the CofQ.</li>
                    <li><b>Description</b> may be any text and is not used in the translation.</li>
                    <li><b>Active</b> allows the user to remove the record from the list without deleting.</li>
                </ul>
                <p>
                <img src={Translation} className="Translation" alt="GOM Translations Page" />
                </p>
                <p />
                <p />
                <h5>Adding New Translations</h5>
                <p>The <b>Add Translation</b> button brings up a form for adding a new record.
                </p>
                <img src={TranslationNew} className="TranslationNew" alt="GOM Translation new record" />

            </div>

        </div>
    );
}
export default TranslationDoc;