import React, { useState, useEffect, useRef } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

// Styling
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  DataGrid
} from '@mui/x-data-grid';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ILRInspectionLotRequestForm(props) {
  const _apiUrl = process.env.REACT_APP_API_URL;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const defaultRow = {
    ilrComponentMapHeaderId: 0,
    siteName: "",
    product: "",
    ilrComponentMapDetailInfo: [],
    modifiedBy: "",
    modifiedOn: ""

  };
  const AddComponentflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
  const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

  const [CompValue, SelectedCompValue] = React.useState(SelectedValue);
  const [site, setSite] = React.useState(SelectedValue.siteName);
  const [rows, setRows] = React.useState(SelectedValue == "" ? [] : SelectedValue.characteristicSpecifications);
    const [insrows, setInsRows] = React.useState([]);


    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

  const CloseDialoginParent = (flag) => {
    props.CloseDialog(flag);
  };

    const saveComponentValues = async () => {

    const serviceUrl = _apiUrl + (AddComponentflag ? "AddIlrInspectionRequest" : "UpdateILRInspectionRequest")

      CompValue.modifiedBy = username;

      await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(CompValue))
          .then(response => {
              if (response.ok) {
                  return response.json();
              }
              throw new Error('Something went wrong.');
          })
          .then(response => {
              if (response.result)
                  CloseDialoginParent(true);
              console.log(response.message);
          })
          .catch(error => {
              console.error(error);
          });

  };
    const LoadILRByInsLot = async () => {

        await fetchDataAuthenticated(_apiUrl + "GetIlrByInspectionLot/" + SelectedValue.inspectionLot)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                // TODO: Wrap this in a function if required or change return object
                setRows(data[0].characteristicSpecifications)
                var element = [];
                for (var i = 0; i < data[0].characteristicSpecifications.length; i++) {
                    for (var j = 0; j < data[0].characteristicSpecifications[i].characteristicInspectionCatalog.length; j++) {
                        element.push(
                            {
                                confirmationNumber: data[0].characteristicSpecifications[i].confirmationNumber,
                                id: data[0].characteristicSpecifications[i].characteristicInspectionCatalog[j].id,
                                characteristicCode: data[0].characteristicSpecifications[i].characteristicInspectionCatalog[j].characteristicCode,
                                characteristicCodeDescription: data[0].characteristicSpecifications[i].characteristicInspectionCatalog[j].characteristicCodeDescription,
                                characteristicCodeGroup: data[0].characteristicSpecifications[i].characteristicInspectionCatalog[j].characteristicCodeGroup,
                                characteristicCodeValuation: data[0].characteristicSpecifications[i].characteristicInspectionCatalog[j].characteristicCodeValuation,
                            });
                    }
                }

                setInsRows(element);
            })
            .catch(error => {
                console.error(error);
            });

    };

  useEffect(() => {
    LoadILRByInsLot();
  }, []);

  const handleTextFieldChange = (event) => {
    CompValue[event.target.id] = event.target.value;
    SelectedCompValue(CompValue);
  };

  //   const [componentGroupName, setcomponentGroupName] = React.useState(SelectedValue.componentGroupName);
  //   const handleChangeComponentGroupName = (event) => {
  //     setcomponentGroupName(event.target.value);
  //     CompValue.componentGroupName = event.target.value;
  //     SelectedCompValue(CompValue);
  //   };

  const columns = [
    { field: 'id', headerName: 'Id', width: 0},
    {
      field: 'confirmationNumber',
      headerName: 'Confirmation Number',
      width: 150,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'masterInspectionCharacteristics',
      headerName: 'Inspection Characteristics',
      width: 200
    },
    {
      field: 'resultType',
      headerName: 'Result Type',
      width: 100
    },
    {
      field: 'characteristicUnit',
      headerName: 'Characteristic Unit',
      width: 150
    },
    {
      field: 'characteristicUnitDescription',
      headerName: 'Unit Description',
      width: 150
    },
    {
      field: 'characteristicDecimalPlaces',
      headerName: 'Decimal Places',
      type: 'number',
      width: 130
    },
    {
      field: 'characteristicUpperLimit',
      headerName: 'Upper Limit',
      width: 100
    },
    {
      field: 'characteristicLowerLimit',
      headerName: 'Lower Limit',
      width: 100
    },
    {
      field: 'characteristicSpecificationStatus',
      headerName: 'Specification Status',
      width: 200
    },
    {
      field: 'characteristicCodeGroupSet',
      headerName: 'Code Group Set',
      width: 150
    }
  ];

  const inscolumns = [
    { field: 'id', headerName: 'Id', width: 0 },
    {
      field: 'confirmationNumber',
      headerName: 'Confirmation Number',
      width: 150,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'characteristicCodeGroup',
      headerName: 'Code Group',
      width: 200
    },
    {
      field: 'characteristicCode',
      headerName: 'Code',
      width: 100
    },
    {
      field: 'characteristicCodeDescription',
      headerName: 'Code Description',
      width: 150
    },
    {
      field: 'characteristicCodeValuation',
      headerName: 'Code Valuation',
      width: 150
    }
  ];

  return (
    <DialogContent>
      <div className='container-fluid csDialogbottom'>
        <div className='container-fluid csDialogbottom'>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="inspectionLot"
                  label="InspectionLot"
                  defaultValue={CompValue.inspectionLot}
                  disabled={true}
                />
              </Box>
            </div>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="materialGroup"
                  label="MaterialGroup"
                  defaultValue={CompValue.materialGroup}
                  disabled={true}
                />
              </Box>
            </div>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="batchId"
                  label="Batch Id"
                  defaultValue={CompValue.batchId}
                  disabled={true}
                />
              </Box>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="id_numeric"
                  label="id_numeric"
                  defaultValue={CompValue.id_numeric}
                  onChange={handleTextFieldChange}
                />
              </Box>
            </div>
          </div>

        </div>

        <div className='clearfix'></div>
        <div className='col-md-12'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Characteristic Specifications" {...a11yProps(0)} />
                <Tab label="Characteristic Inspection Catalog" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div className='col-md-12'>
                <Box
                  sx={{
                    height: 500,
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary',
                    },
                    '& .textPrimary': {
                      color: 'text.primary',
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      ...rows.initialState,
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    getRowId={(row) => row.id}
                    columnVisibilityModel={{ id: false }}
                  />
                </Box>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div className='col-md-12'>
                <Box
                  sx={{
                    height: 500,
                    width: '100%',
                    '& .actions': {
                      color: 'text.secondary',
                    },
                    '& .textPrimary': {
                      color: 'text.primary',
                    },
                  }}
                >
                  <DataGrid
                    rows={insrows}
                    columns={inscolumns}
                    initialState={{
                      ...insrows.initialState,
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    getRowId={(row) => row.id}
                    columnVisibilityModel={{ id: false }}
                  />
                </Box>
              </div>
            </CustomTabPanel>
          </Box>
        </div>
        <div className='clearfix'></div>
        <div className='row'>
          <div className='col-md-9'></div>
          <div className='col-md-3 row'>
            <div className='col-md-6'>
              <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
            </div>
            <div className='col-md-6'>
              <button type="button" className="btn btn-outline-primary" onClick={saveComponentValues}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
export default ILRInspectionLotRequestForm;