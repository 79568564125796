import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                </div>
                <div className='col-md-2'>
                </div>
            </div>
            <div align="center">
                <ul>
                    <li><Link to='/CofQConfiguration' className='submenu-item'>CofQ Configuration</Link></li>
                <li><Link to='/CofAConfiguration' className='submenu-item'>CofA Configuration</Link></li>
                <li><Link to='/DocLand' className='submenu-item'>Documentation</Link></li>

                </ul>
            </div>
        </div>
    )
}

export default Home;
