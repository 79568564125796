import React from 'react'
import { Link } from 'react-router-dom';
import ILRInspectionLotRequestA from "../../../../Images/ILRInspectionLotRequestA.jpg";
import ILRInspectionLotRequestB from "../../../../Images/ILRInspectionLotRequestB.jpg";

function ILRReprocess(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>ILR - GEMS Lot Request</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/GEMSLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>

                <h5>View or Reprocess ILR Lot Request</h5>
                <ul>
                    <li><b>ILR Inspection Lot Request</b> are viewed from this screen.</li>
                    <li><b>Filter or Sort</b> on any column using the shortcuts on the row headers.</li>
                </ul>
                <p>
                <img src={ILRInspectionLotRequestA} className="ILRInspectionLotRequestA" alt="ILR Inspection Lot Request A" />
                </p>

                <ul>
                    <li><b>Status</b> incidates what stage of processing the request is in.</li>
                    <li><b>Status Message</b> will provide brief details of why the status was assigned.</li>
                    <li><b>ReProcess</b> allows the user to submit the request for immediate reprocessing.  The request can be New, Ready, Failed or Cancelled.  On ReProcessing,
                        the status will be reset and the ILR submitted to the queue for processing. </li>
                    <li><b>Validate</b> will restart the validation process.  This is designed to be used to restart the process or verify changes made to fix validation issues.</li>
                    <li><b>Process</b> allows the user to submit the request for immediate reprocessing and disregard and validation or sample errors. USE WITH CAUTION and verify data in GEMS.</li>
                </ul>
                <p>
                    <br />
                    <img src={ILRInspectionLotRequestB} className="ILRInspectionLotRequestB" alt="ILR Inspection Lot Request B" />
                </p>
                <h5>Status</h5>
                <ul>
                    <li><b>N</b> New - New request or treat request as new.</li>
                    <li><b>R</b> Ready - The request has been validated against the Component Mapping and is ready for reply </li>
                    <li><b>W</b> Waiting - The request is waiting for the Sample to be authorized.  IF</li>
                    <li><b>C</b> Complete - Request has been completed and sent to GEMS.  No reprocessing allowed.</li>
                    <li><b>F</b> Failed - Interface error between the CertApp and XI or the current interface method.  The application will resend the reply.</li>
                    <li><b>H</b> Hold - An error from the sample has been detected and the request is on Hold until fixed and submitted for Reprocess. </li>
                    <li><b>X</b> Cancelled - The request can be Reprocessed from the cancelled status.</li>
                </ul>

            </div>

        </div>
    );
}
export default ILRReprocess;