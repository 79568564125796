import React from 'react'
import { Link } from 'react-router-dom';
import GOMComponentMappingGrid from "../../../../Images/GOMComponentMappingGrid.jpg";
import GOMComponentMappingForm from "../../../../Images/GOMComponentMappingForm.jpg";

function ComponentMappingDoc(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM Component Mapping Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/GOMLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GOM Component Mapping</h5>
                <p>The Component Mapping is the list of components that will be sent for GOM for inclusion on the CofQ.  The list here is compared to the results from SampleManager
                    and any matching components are sent to GOM.  If more components are on the Sample than are mapped, the unmapped results are ignored.  If more than one record
                    in this component map with the Customer Component are mapped to different analysis component combinations in SampleManager, the matching records are sent to GOM.
                    The order Number is sent to GOM for ordering on the CofQ, but this is not strickly enforced in this list so number may be random or repeated.
                </p>
                <p> <b>Government Levels and Components</b> only apply to contracts with US government and at the time this code was rewritten, there were no active governemnt
                    contracts.  The code was tested in the CertApp but no Governemnt CofQ were testing in GOM.
                </p>
                <ul>
                    <li><b>Site</b> SampleManager instance or site to relate records in this Cert App to other data.</li>
                    <li><b>Component Group Name</b> is any name the site finds useful.  Often this is a product and optionally a language.</li>
                    <li><b>Level Id</b> SampleManager MLP Level Id for mapping product limits to send to GOM.  If no level is specified, the limits will not appear on the CofQ.</li>
                    <li><b>Government Level Id</b> Optional MLP Level Id for Government contracts.  These are always open ended limits such as&lt;=5.0 or &gt;=99.5. </li>
                    <li><b>Add 3PL Comment</b> if True, the Thrid Party Lab comment will be added for analysis supplied by 3PL.</li>
                    <li><b>Window Add Days</b> number of days to add to the Blend Date to search for a sample.</li>
                    <li><b>Window Subract Days</b> number if days to subract from Blend Date to search for sample.</li>
                    <li><b>Description</b> any descriptive value.</li>
                </ul>
                <p />
                <img src={GOMComponentMappingGrid} className="GOMComponentMappingGrid" alt="GOM Component Mapping Grid" />

                <ul>
                    <li><b>Analysis</b> SampleManager Analysis.</li>
                    <li><b>Component</b> SampleManager result component.</li>
                    <li><b>Order Number</b> is the order that the components will appear on the CofQ.</li>
                    <li><b>Customer Component</b> as it is to be listed on the CofQ in GOM.</li>
                    <li><b>Government Component</b> as it is to be listed on the CofQ in GOM.  This is generally a numeric value for US Government contracts.</li>
                    <li><b>Decimal Places</b> expected in the result from SampleManager.</li>
                    <li><b>Active</b> allows the user to remove the record from the list without deleting.</li>
                </ul>
                <p />
                <img src={GOMComponentMappingForm} className="GOMComponentMappingForm" alt="GOM Component Mapping Form" />

            </div>

        </div>
    );
}
export default ComponentMappingDoc;