import React from 'react'
import { Link } from 'react-router-dom';
function CofAConfiguration(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GEMS CofA Configuration</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>

                <h4>To test or setup a product the following steps as needed.</h4>
                <p />
                This is intended to be an outline of the steps.  Details of how to setup each step are in the Documentation.
                <p />
                <h5>GEMS will need to provide the following to build the component mapping:</h5>
                <ul>
                    <li>GEMS Site Code and Plant Code that will be associated with the ILR Request.</li>
                    <li>Material Group - This is the value that always starts with 1, not the Material Number that starts with 5.</li>
                    <li>For each result reported to GEMS.</li>
                    <ul>
                        <li>Mic - [masterInspectionCharacteristics]</li>
                        <li>Result Type - Numeric or Code Group Set</li>
                        <li>If the Result is a Code Group Set, name and details about the Code Group.</li>
                        <li>Unit Of Measure that the SampleManager result will use for each result.</li>
                        <li>Decimal Places that the SampleManager result will use for each result.</li>
                    </ul>
                </ul>
                <p />
                <h5>Setup the CertApp:</h5>
                <ul>
                    <li>Verify the Site Code and Plant Code that GEMS will send are correctly mapped in the ILR Site table using menu option GEMS Mapping / ILR Site.</li>
                    <ul>
                        <li>The method of finding the sample and options to validate places, Units Of Measure and Limits are set at the Site / Plant level.</li>
                    </ul>
                    <li>Confirm or create a record to map the Material Group to the SampleManager Product using menu option GEMS Mapping / ILR Material Map.</li>
                    <ul>
                        <li>This record links the SAP Material Group to the SampleManager Product.</li>
                    </ul>
                    <li>Confirm or create a ILR Component Mapping Record using menu option GEMS Mapping / ILR Component Mapping.</li>
                </ul>
                <p />
                Refer to the links at Home / Documentation / GEMS for details on mapping and setup.
                <p />
                <h5>Follow the series of events the site expects to use</h5>
                There are 2 conditions that must be met to see the result transferred from SampleManager to GEMS:
                <ul>
                    <li>Create the Production Write On in GEMS to trigger an ILR</li>
                    <li>Login a sample in SampleManager, enter the results and Authorize the sample.</li>
                </ul>
                <p />
                The interface should handle any sequence of events.  The sample can be created and authorized first or the production write on can be completed first.  Also the sample can be
                logged in but not authorized when the ILR is received.
                <p />
                When the ILR request is sent from GEMS, it should be available in the GEMS Mapping / ILR Inspection Lot Request.  The status of the request will be displayed along with
                details such as the ID_NUMERIC of the sample when it is found.Scrolling to the right of the screen will show the options to processing and viewing of details about the request.
                Additionally information about the processing of the Inspection Lot Request can be viewed on the Logs tab.  Set the Site, Dates, Log Type = GEMS and click filter to see logs of
                the processing steps.
                <p />
                Log details are in JSON format and can be copied and saved to a.JSON file for viewing in Chrome or any text editor capable of formatting JSON text.


            </div>

        </div>
    );
}
export default CofAConfiguration;

