import React from 'react'
import { Link } from 'react-router-dom';
import CertAppAzure from "../../../../Images/CertAppAzure.png";
import CertAppArchitecture from "../../../../Images/CertAppArchitecture.png";

function Architecture(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>CertApp Architecture</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/SupportLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>Azure Arcitecture</h5>
                <p />
                <img src={CertAppAzure} className="CertAppAzure" alt="CertApp Azure" width="90%" />

                <p />
                <h5>CertApp Overall Arcitecture</h5>
                <p />
                <p />
                <img src={CertAppArchitecture} className="CertAppArchitecture" alt="CertApp Architecture" width="100%"/>

            </div>

        </div>
    );
}
export default Architecture;