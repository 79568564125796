import React from 'react'
import { Link } from 'react-router-dom';
import GOMSiteGrid from "../../../../Images/GOMSiteGrid.jpg";
import GOMSiteForm from "../../../../Images/GOMSiteForm.jpg";

function GOMSiteDoc(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM Site Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/GOMLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>

                <h5>GOM Site Mapping</h5>
                <ul>
                    <li><b>SAP Site Code</b> is provided by SAP and maps to the SampleManager Instance.</li>
                    <li><b>Site</b> SampleManager instance or site to relate records in this Cert App to other data.</li>
                    <li><b>Description</b> is any descriptive value.</li>
                    <li><b>Third Part Lab ID</b> value in the SampleManager SUPPLIER table XOM_SUPPLIER_TYPE column that identifies the lab suppling the results as a
                           third party lab.  This will trigger a comment to be sent to GOM.</li>
                    <li><b>Third Party Lab Comment</b> is the comment sent to GOM for a third party lab. It is configurable here, but should always be <b>Third Part Lab</b> to match the setup in GOM. </li>
                    <li><b>Periodic Analysis</b> is that VERSIONED_ANALYSIS table XOM_TEST_TYPE column that indicates the test is a periodic test and triggers a comment to GOM.</li>
                    <li><b>SAP GOM</b> will add one of more asterics to the CofQ to indicate Third Part Lab, Periodic or other comments like Third Party Lab, Periodic.</li>
                    <li><b>Sample Type</b> is the expected SAMPLE_TYPE in SampleManager used to serach for samples.</li>
                    <li><b>Status COQ Value</b> is the value on the SAMPLE table in the XOM_STATUS_COQ or STATUS_COQ column that idicates the sample is ready for CofQ.  In all cases this should be <b>T</b> unless
                        the local calculation is modified.  A value of True will be stored as T in the database.</li>
                    <li><b>SM API Connection</b> is provided by support and used to call the SM-API.  The SM-API provides the connection to the database.</li>
                </ul>
                <p />
                <img src={GOMSiteGrid} className="GOMSiteGrid" alt="GOM Site Grid" />
                <p />
                <ul>
                    <li><b>Add Site</b> to create a new records for SAP Site Codes.</li>
                    <li><b>Edit Button</b> brings up the editor for a specific record.</li>
                </ul>
                <p />
                <img src={GOMSiteForm} className="GOMSiteForm" alt="GOM Site Form" />

            </div>

        </div>
    );
}
export default GOMSiteDoc;