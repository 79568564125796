import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { fetchDataAuthenticated } from '../utilities/HttpUtils';

//Dialogs
import CorporateDlg from './Dialogs/FooterDialogs/CorporateDlg';
import SupportDlg from './Dialogs/FooterDialogs/SupportDlg';
import AboutDlg from './Dialogs/FooterDialogs/AboutDlg';

function Footer() {

    const [version, setVersion] = useState("");
    const [copywriteInfo, setCopywriteInfo] = useState("");
    const [corporateDlgIsOpen, setCorporateDlgIsOpen] = useState(false);
    const [supportDlgIsOpen, setSupportDlgIsOpen] = useState(false);
    const [aboutDlgIsOpen, setAboutDlgIsOpen] = useState(false);
    
    const _apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchDataAuthenticated(_apiUrl + 'api/Info/app')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to retrieve app info");
            })
            .then(data => {
                setVersion(data.version);
                setCopywriteInfo(data.copywriteInfo);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const toggleCorporateDlg = () => {
        setCorporateDlgIsOpen(!corporateDlgIsOpen);
    };

    const toggleSupportDlg = () => {
        setSupportDlgIsOpen(!supportDlgIsOpen);
    };

    const toggleAboutDlg = () => {
        setAboutDlgIsOpen(!aboutDlgIsOpen);
    };

    return (
        <>
            <Container fluid className="footer border-top">
                <Row>
                    <Col className="d-flex justify-content-start">
                        <Link className="link me-2" to='#' onClick={toggleAboutDlg}>About</Link>
                        <Link className="link me-3" to='#' onClick={toggleSupportDlg}>Support</Link>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Link className="link me-2" to='#' onClick={toggleCorporateDlg}>Corporate Separateness Notice</Link>
                            <p className="text-dark d-none d-md-inline-block">{copywriteInfo}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <CorporateDlg isOpen={corporateDlgIsOpen} toggleModal={toggleCorporateDlg} />
            <SupportDlg isOpen={supportDlgIsOpen} toggleModal={toggleSupportDlg} />
            <AboutDlg isOpen={aboutDlgIsOpen} toggleModal={toggleAboutDlg} version={version} copywrite={copywriteInfo} />
        </>
    )
}

export default Footer;