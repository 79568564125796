import React, { useState } from "react";
import { Link } from 'react-router-dom';

const GEMSMappingSubMenu = () => {
    const [dropdown, setDropdown] = useState(false);
    return (
        <ul className={dropdown ? "subMenu clicked" : "subMenu"} onClick={() => setDropdown(!dropdown)}>
            <li><Link to='/ILRSiteDetails' className='submenu-item'>ILR Site</Link></li>
            <li><Link to='/ILRComponentMapping' className='submenu-item'>ILR Component Mapping</Link></li>
            <li><Link to='/ILRMaterialMap' className='submenu-item'>ILR Material Mapping</Link></li>
            <li><Link to='/ILRInspectionLotRequest' className='submenu-item'>Inspection Lot Request</Link></li>
        </ul>
    );
};

export default GEMSMappingSubMenu;